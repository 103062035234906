import React, { Component } from 'react'
import Dice from "./Logic/Dice.mjs";
import Player from "./Logic/Player.mjs";
import GameController from "./Logic/GameController.mjs";
import Board from './Components/Board.jsx';
import StartSimulation from './Components/StartSimulation.jsx';
import './App.scss';



export default class App extends Component {
  static maxPause = 600;
  constructor(props) {
    super(props);
    const dice1 = new Dice([1, 2, 3, 4, 5, 6]);
    const dice2 = new Dice([1, 2, 3, 4, 5, 6]);
    const dice3 = new Dice([1, 2, 3, 4, 5, 6]);
    const dice4 = new Dice([1, 2, 3, 4, 5, 6]);
    this.anna = new Player('Anna', 'yellow', dice3);
    this.ben = new Player('Ben', 'green', dice2);
    this.carol = new Player('Carol', 'red', dice1);
    this.daniel = new Player('Daniel', 'blue', dice4);
    this.gameController = new GameController([this.anna, this.ben, this.carol, this.daniel]);
    this.state = {
      playerPositions: {
        green: [0, -1, -1, -1],
        red: [10, -1, -1, -1],
        blue: [20, -1, -1, -1],
        yellow: [30, -1, -1, -1]
      },
      interval: null
    };
    this.animationPause = App.maxPause / 2;
    this.startPlayerIndex = 1;
  }
  
  move(){
    if (this.state.animation && this.gameController.players.every(player => player.hasWon())) {
      this.stopAutomatic();
      return;
    }
    this.gameController.makeOneMove();
    this.updatePosition();
  }
  updatePosition() {
    this.setState({
      playerPositions: this.gameController.getPlayerPositions()
    });
  }

  reset() {
    const startPlayer = this.gameController.players[this.startPlayerIndex];
    this.gameController.resetBoard(startPlayer);
    this.updatePosition();
    this.increaseStartPlayerIndex();
  }
  increaseStartPlayerIndex() {
    this.startPlayerIndex = (this.startPlayerIndex + 1) % this.gameController?.players.length;
    if (!this.gameController.players[this.startPlayerIndex].isActive) this.increaseStartPlayerIndex();
  }

  stopAutomatic() {
    clearInterval(this.state.interval);
    this.setState({ interval: null });
  }
  startAutomatic() {
    this.stopAutomatic();
    this.setState({ interval: setInterval(() => this.move(), this.animationPause) });
  }
  skipGame() {
    this.gameController.playUntilWin();
    this.updatePosition();
  }
  setNewSpeed(newSpeed) {
    this.animationPause = App.maxPause - newSpeed;
    if (this.state.interval !== null) {
      this.stopAutomatic();
      this.startAutomatic();
    }
  }
  simulateGames(numberOfGamesToSimulate) {
    this.stopAutomatic();
    for (let i = 0; i < numberOfGamesToSimulate; i++) {
      this.reset();
      this.gameController.playUntilWin();
    }
    this.reset();
  }


  render() {
    const buttons = <div className="button-container">
        <div className="action-button" onClick={() => this.move()}> <i className="fa fa-lg fa-step-forward"></i></div>
        <div className="action-button" onClick={() => this.reset()}> <i className="fa fa-lg fa-sync-alt"></i></div>
        {
          this.state.interval ? 
              <div className="action-button" onClick={() => this.stopAutomatic()}> <i className="fa fa-lg fa-stop"></i></div>
              :
              <div className="action-button" onClick={() => this.startAutomatic()}> <i className="fa fa-lg fa-play"></i></div>
        }
        {
          // den skip-Button nur rendern, falls es noch keinen Gewinner gibt
          this.gameController.winner ? null :
          <div className="action-button" onClick={() => this.skipGame()}> <i className="fa fa-lg fa-forward"></i></div>
        }
        <div className="speed-slider">
          <label htmlFor="speed-slider">Animation speed:</label>
          <input type="range" id="speed-slider" min="0" max={App.maxPause} onChange={event => this.setNewSpeed(event.target.value)}></input>
        </div>
      </div>

    return (
      <div className="App">
        <Board playerPositions={this.state.playerPositions} players={[this.anna, this.ben, this.carol, this.daniel]} winner={this.gameController.winner} children={buttons} updateBoard={() => this.updatePosition()}>
        </Board>
        <StartSimulation simulateGames={numberOfGamesToSimulate => this.simulateGames(numberOfGamesToSimulate)}/>
      </div>
    )
  }
}