import React, {useState, useRef} from 'react'

export default function Dice(props) {
	const [diceFaces, updateDiceFaces] = useState(props.dice.values);
	let inputField = useRef(null);
	const addNewFaceToDice = () => {
		const value = Number(inputField.current.value);
		if (isNaN(value) || value <= 0 || value >= 50) return;
		const newFaces = [...diceFaces];
		newFaces.push(value);
		props.dice.setNewValues(newFaces);
		updateDiceFaces(newFaces);
	}
	return (
		<div className='dice-display'>
			Dice:
			<ul>
				{diceFaces.map((num, i) =>
				<li key={i}>
					{num}
						<i className='fa fa-trash-alt button' onClick={() => {
							const newFaces = [...diceFaces]
							newFaces.splice(i, 1);
							props.dice.setNewValues(newFaces);
							updateDiceFaces(newFaces);
						}}></i>
				</li>)} 
			</ul>
			Add new Face: 
			<div>
				<input type="number" ref={inputField} min="1" max="50"></input>
				<i className='fa fa-plus button' onClick={addNewFaceToDice}></i>
			</div>
		</div>
	)
}
