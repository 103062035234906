import React from 'react';
import Field from './Field'
import mapGameLogicToView from './mapPositions.mjs';
import Player from './Player';

export default function Board(props) {
	const styleClasses = mapGameLogicToView(props.playerPositions);
	const fields = [];
	styleClasses.forEach((styleClass, i) => {
		fields.push(<Field styleClass={styleClass} key={i}/>)
	})

	return (
		<div className="board-container">
			<div className="board-border">
				<div className="board">
					<Player player={props.players[0]} isWinner={props.winner === props.players[0]} updateBoard={props.updateBoard}/>
					<Player player={props.players[1]} isWinner={props.winner === props.players[1]} updateBoard={props.updateBoard}/>
					{fields}
					<Player player={props.players[2]} isWinner={props.winner === props.players[2]} updateBoard={props.updateBoard}/>
					<Player player={props.players[3]} isWinner={props.winner === props.players[3]} updateBoard={props.updateBoard}/>
				</div>
			</div>
			{props.children}
		</div>
	)
}