const normalFields = [6, 17, 28, 39, 50, 51, 52, 53, 54, 65, 76, 75, 74, 73, 72, 83, 94, 105, 116, 115, 114, 103, 92, 81, 70, 69, 68, 67, 66, 55, 44, 45, 46, 47, 48, 37, 26, 15, 4, 5];

const reserveFields = {
	green: [9, 10, 20, 21],
	red: [109, 108, 119, 120],
	blue: [99, 100, 110, 111],
	yellow: [0, 1, 11, 12],
	all: [9, 10, 20, 21, 109, 108, 119, 120, 99, 100, 110, 111, 0, 1, 11, 12]
};

const homeFields = {
	green: [16, 27, 38, 49],
	red: [61, 62, 63, 64],
	blue: [71, 82, 93, 104],
	yellow: [56, 57, 58, 59],
	all: [16, 27, 38, 49, 61, 62, 63, 64, 56, 57, 58, 59, 5, 71, 82, 93, 104]
};

const getColor = (dicePos, playerPositions) => {
	if (playerPositions.green?.includes(dicePos)) return 'green';
	if (playerPositions.red?.includes(dicePos)) return 'red';
	if (playerPositions.blue?.includes(dicePos)) return 'blue';
	if (playerPositions.yellow?.includes(dicePos)) return 'yellow';
	return '';
}

const getFieldsWithPlayerHavingColor = (color, piecePositions) => {
	if (!(color in piecePositions)) return [];
	const result = [];
	let numOfPiecesInReserve = 0;
	for (let piece of piecePositions[color]) {
		if (piece <= -1) {
			result.push(reserveFields[color][numOfPiecesInReserve]);
			numOfPiecesInReserve++;
		} else if (piece >= 40) {
			if (color === 'red' || color === 'blue')
				result.push(homeFields[color][43 - piece]);
			else
				result.push(homeFields[color][piece - 40]);
		} else {
			result.push(normalFields[piece]);
		}
	}
	return result;
}

// input: player position as array (e.g. [1, 1, 3, -1]), where:
	// -  numbers from 0 - 39 are normal fields
	// - -1 indicates that the piece is in the home
	// - 40 - 43 means the piece is in aim position 
// output: array with css-class-Strings for the 121 grid positions
const mapGameLogicToView = (playerPositions) => {
	const playersOnGreenFields = getFieldsWithPlayerHavingColor('green', playerPositions);
	const playersOnRedFields = getFieldsWithPlayerHavingColor('red', playerPositions);
	const playersOnBlueFields = getFieldsWithPlayerHavingColor('blue', playerPositions);
	const playersOnYellowFields = getFieldsWithPlayerHavingColor('yellow', playerPositions);
	let result = [];
	for (let dice = 0; dice < 121; dice++){
		let styleClass = 'hidden';
		const isNormalField = normalFields?.includes(dice);
		const isHomeField = homeFields.all?.includes(dice);
		const isReserveField = reserveFields.all?.includes(dice);
		if (isNormalField) styleClass = 'normal';
		else if (isHomeField) styleClass = 'home ' + getColor(dice, homeFields);
		else if (isReserveField) styleClass = 'reserve ' + getColor(dice, reserveFields);

		if (playersOnGreenFields.includes(dice)) styleClass += ' player player-green';
		if (playersOnRedFields.includes(dice)) styleClass += ' player player-red';
		if (playersOnBlueFields.includes(dice)) styleClass += ' player player-blue';
		if (playersOnYellowFields.includes(dice)) styleClass += ' player player-yellow';

		// color the start Fields
		if (dice === 6) styleClass += ' green start-field';
		if (dice === 76) styleClass += ' red start-field';
		if (dice === 114) styleClass += ' blue start-field';
		if (dice === 44) styleClass += ' yellow start-field';
		result.push(styleClass);
	}
	return result;
}

export default mapGameLogicToView;