export default class Player{
	static numberOfFields = 40;

	constructor(name, color, dice) {
		this.name = name;
		if (color === 'red') this.fieldShift = Player.numberOfFields / 4;
		else if(color === 'blue') this.fieldShift = Player.numberOfFields / 2;
		else if(color === 'yellow') this.fieldShift = Player.numberOfFields * 3 / 4;
		else this.fieldShift = 0;
		this.color = color;
		this.dice = dice;
		this.lastThrownNumber = [];
		this.reset();
		this.winCount = 0;
		this.isActive = true;
	}

	makeMove() {
		const thrownNumber = this.dice.throw();
		// to display the last couple of moves in the GUI
		this.lastThrownNumber.unshift(thrownNumber);
		if (this.lastThrownNumber.length > 6) this.lastThrownNumber.pop();

		this.sortPieces();
        	// 6 was thrown and not all pieces are out of home position
		if (thrownNumber === 6 && this.pieces[this.pieces.length - 1] === -1 && this.pieces.every(e => e !== 0)) {
			this.pieces[this.pieces.length - 1] = 0;
			return [this.getGlobalPositions(), true];
		}

        	// as long as move fails try to make a move
		for (let i = 0; i < 4; i++){
			if (this.isMovingPieceSuccessful(i, thrownNumber)) {
				this.pieces[i] += thrownNumber;
				// move was made -> don't try to make any other moves
				break;
			}
		}
		return [this.getGlobalPositions(), thrownNumber === 6];
	}
	
	sortPieces() {
		// ist kein Spieler mehr im Haus?
		if (this.pieces.every(piece => piece !== -1)) {
			// einfach nur absteigend sortieren
			this.pieces.sort((a, b) => b - a);
		} else {
			// sort pieces descending, EXCEPT if a piece is on position 0 (-> house position): it has to be moved first
			this.pieces = this.pieces.sort((a, b) => {
				if (a === 0) return -1;
				if (b === 0) return 1;
				return b - a;
			});
		}
	}

	isMovingPieceSuccessful(indexOfPiece, thrownNumber) {
		const newPos = this.pieces[indexOfPiece] + thrownNumber;
		// piece would go out of bounds (+4 for the home fields)
		if (newPos >= Player.numberOfFields + 4) return false;
		// piece would hit one of own team
		for (let i = 0; i < this.pieces.length; i ++) {
			if (i !== indexOfPiece && this.pieces[i] === newPos) return false;
		}
		// check if player needs 6 to get out of house
		if (this.pieces[indexOfPiece] === -1 && thrownNumber !== 6) return false;
		return true;
	}

	checkIfCaptured(newPositions) {
		for (let i = 0; i < this.pieces.length; i++){
			const localPos = this.pieces[i];
			if (localPos >= Player.numberOfFields || localPos === -1) continue;
			const globalPos = this.localToGlobalPos(this.pieces[i]);
			if (newPositions.includes(globalPos)) this.pieces[i] = - 1;
		}
	}

	hasWon() {
		// are all 4 pieces in home?
		return 4 === this.pieces.reduce((numOfPiecesInHome, currPiece) => {
			if (currPiece >= Player.numberOfFields) return numOfPiecesInHome + 1;
			return numOfPiecesInHome;
		}, 0);
	}

	globalToLocalPos(pos) {
		if (pos >= Player.numberOfFields || pos === -1) return pos;
		return (pos - this.fieldShift) % Player.numberOfFields;
	}

	localToGlobalPos(pos) {
		// piece is on home -> no equivalent global position; return same as default
		if (pos >= Player.numberOfFields || pos === -1) return pos;
		return (pos + this.fieldShift) % Player.numberOfFields;
	}

	getGlobalPositions() {
		if (!this.isActive) return [];
		const globalPositions = [];
		for (let position of this.pieces) {
			globalPositions.push(this.localToGlobalPos(position));
		}
		return globalPositions;
	}

	reset() {
		this.pieces = [0, -1, -1, -1];
	}
}