export default class Dice{
	constructor(values=[1, 2, 3, 4, 5, 6]) {
		this.setNewValues(values);
	}

	throw() {
		const randIndex = Math.floor(Math.random() * this.values.length);
		return this.values[randIndex];
	}
	setNewValues(values) {
		this.values = values;
		this.alwaysLooses = !values.includes(6);
	}
}