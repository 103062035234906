import React, { useState } from 'react';
import Dice from './Dice';

export default function Player(props) {
	const style = {};
	if (props.player?.color === 'yellow' || props.player?.color === 'green') style['top'] = '0px';
	else style['bottom'] = '0px';
	if (props.player?.color === 'yellow' || props.player?.color === 'blue') {
		style['left'] = '0px';
		style['transform'] = 'translateX(calc(-100% - 30px))';
	}
	else {
		style['right'] = '0px';
		style['transform'] = 'translateX(calc(100% + 30px))';
	}
	const lastThrownNumbers = props.player?.lastThrownNumber.map((num, i) => <li key={i}>{num}</li>);

	const [isActive, setActive] = useState(true);
	const toggleAcitvity = function() {
		props.player.isActive = !isActive;
		setActive(!isActive);
		props.updateBoard();
	}

	return (
		<div className={`player-display ${props.isWinner ? "winner" : ""}`} style={style}>
			<h2>{props.player?.name} - {props.player?.winCount} Wins</h2>
			IsActive: <input type="checkbox" defaultChecked onClick={toggleAcitvity}></input> <br/>
			<Dice dice={props.player?.dice}/>
			Last thrown numbers:
			<ol>
				{lastThrownNumbers}
			</ol> 
		</div>
	)
}
