export default class GameController{
	constructor(players = []) {
		this.players = players;
		this.currPlayerIndex = 0;
		this.winner = null;
	}

	playUntilWin() {
		let winner = null;
		let moveCount = 0;
		while (!winner  && moveCount < 1_000) {
			winner = this.makeOneMove();
			moveCount++;
		}
		return winner;
	}

	makeOneMove() {
		if (this.currPlayerIndex >= this.players.length) this.currPlayerIndex = 0;
		const currPlayer = this.players[this.currPlayerIndex];
		this.currPlayerIndex++;
		if (!currPlayer.isActive) return this.makeOneMove();

		let newPosOfPieces, anotherMoveRequired;
		let moveCount = 0; // to prevent program crashes: e.g. do-while-loop is infinite if a dice has 6 on all sides
		do{
			[newPosOfPieces, anotherMoveRequired] = currPlayer.makeMove();

			// check if current Player has just won
			if (currPlayer.hasWon() && this.winner === null) {
				this.winner = currPlayer;
				currPlayer.winCount++;
				return currPlayer;
			}
			// broadcast new position of the piece that was just moved
			for (let player of this.players)
				if (player !== currPlayer) player.checkIfCaptured(newPosOfPieces);
			moveCount++;
		} while (anotherMoveRequired && moveCount < 10_000)

		return null; // curr Player hasn't won :(
	}

	resetBoard(startPlayer) {
		this.currPlayerIndex = this.players.indexOf(startPlayer);
		this.winner = null;
		for (let player of this.players) {
			player.reset();
			player.lastThrownNumber = [];
		}
	}

	// only for rendering purposes
	getPlayerPositions() {
		const result = {};
		for (let player of this.players) {
			result[player.color] = player.getGlobalPositions();
		}
		return result;
	}
}