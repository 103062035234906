import React, { useState } from 'react'

export default function StartSimulation(props) {
	const [numberOfGameToSimulate, updateNumberOfGamesToSimulate] = useState(100);
	return (
        	<div className='start-many-simulations'>
          		<h2>Simulate {numberOfGameToSimulate} games</h2>
			<div>
				<input type="range" min="1" max="1000" onChange={e => updateNumberOfGamesToSimulate(e.target.value)}></input>
				<div className='action-button' onClick={() => props.simulateGames(numberOfGameToSimulate)}>Go!</div>
			</div>
		</div>
	)
}
